import React from 'react';
import cn from 'classnames';
import BaseLink from '../BaseLink/BaseLink';
import Header from '../Header/Header';
import PageTitle from '../PageTitle/PageTitle';
import SectionTitle from '../SectionTitle/SectionTitle';
import socTwoLogo from '../../images/soc-2-logo.png';
import * as styles from './SecurityAndPrivacyMain.module.scss';

const content = [
  {
    title: 'Data security and privacy',
    text: (
      <>
        Iterative products collect and use only necessary data to function
        properly. We retain customer data for as long as an account is active,
        as needed to provide services to customers, or in accordance with the
        agreement(s) between Iterative and the customer, unless Iterative is
        required by law to dispose of it earlier or keep it longer. Iterative
        does not use any personal information collected in the course of doing
        business for commercial purposes. Please read our full privacy policy at{' '}
        <BaseLink
          className={styles.link}
          href="https://dvc.org/doc/user-guide/privacy"
        >
          https://dvc.org/doc/user-guide/privacy
        </BaseLink>
        .
      </>
    ),
  },
  {
    title: 'Open-source',
    text: (
      <>
        Most of our MLOps solutions are open source and thereby subject to
        public review. Security related to our open source tools would be
        managed by the user as our tools are downloaded locally. Users manage
        their own credentials and security policies across resources like
        clouds, storage, and Git service. There are logging functionality that
        send anonymized usage data back to Iterative. Users may opt out of this
        logging. We'll promptly address any security issues that are brought up
        by the community. Please let us know at{' '}
        <BaseLink className={styles.link} href="https://github.com/iterative">
          https://github.com/iterative
        </BaseLink>
        .
      </>
    ),
  },
  {
    title: 'Compliance',
    text: (
      <>
        We're in the process of achieving SOC2 compliance and will be able to
        provide relevant documentation in the next 1-2 months.
      </>
    ),
    image: socTwoLogo,
    imageAlt: 'SOC 2 logo',
  },
  {
    title: 'Studio permissions and security',
    text: (
      <>
        As part of our GitOps philosophy, Studio only takes as much information
        as necessary from your Git service to display experiments, data sets
        used, metrics, and hyper parameters. Studio only has access to
        repositories that customer Git services allow. By default, Studio does
        not access any of the actual data used across your models. Your data
        remains protected by your cloud credentials (e.g., AWS login, etc.). You
        may allow Studio access to storage for additional information to be
        displayed by Studio, but this is optional. Access controls to
        repositories may be granularly managed directly through a customer's
        respective Git service (GitHub app, GitLab admin settings, etc.).
      </>
    ),
  },
];

function SecurityAndPrivacyMain() {
  return (
    <>
      <Header>
        <PageTitle
          className={styles.title}
          title="Security and privacy at Iterative"
          text="Iterative's products are trusted by thousands of organizations. We place security as a core requirement during product development and maintain regular internal and external security assessments. Customers may request all relevant security documents from us as they evaluate our products."
        />
      </Header>
      {content.map(({ title, text, image, imageAlt = '' }, i) => (
        <section
          className={cn(styles.section, image && styles.section_withImage)}
        >
          <SectionTitle
            className={styles.section__title}
            key={i}
            title={title}
            hideSectionLine
          />
          <p className={styles.section__text}>{text}</p>
          {image && (
            <img className={styles.section__img} src={image} alt={imageAlt} />
          )}
        </section>
      ))}
    </>
  );
}

export default SecurityAndPrivacyMain;
