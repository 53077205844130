import React from 'react';
import PropTypes from 'prop-types';
import NavBar from '../NavBar/NavBar';
import { header } from './Header.module.scss';

export default function Header({ children }) {
  return (
    <header className={header}>
      <NavBar />
      {children}
    </header>
  );
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
};
