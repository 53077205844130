import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Title from '../Title/Title';
import {
  pageTitle,
  pageTitle__text,
  pageTitle_isMain,
} from './PageTitle.module.scss';

export default function PageTitle({ title, text, isMain, className }) {
  return (
    <div className={cn(pageTitle, isMain && pageTitle_isMain, className)}>
      <Title size="l" title={title} />
      {text && <p className={pageTitle__text}>{text}</p>}
    </div>
  );
}

PageTitle.propTypes = {
  isMain: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.any,
};
