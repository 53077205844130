import React from 'react';
import Layout from '../components/Layout/Layout';
import SecurityAndPrivacyMain from '../components/SecurityAndPrivacyMain/SecurityAndPrivacyMain';
import Footer from '../components/Footer/Footer';

function SecurityAndPrivacy() {
  return (
    <Layout title="Security and Privacy">
      <SecurityAndPrivacyMain />
      <Footer />
    </Layout>
  );
}

export default SecurityAndPrivacy;
